<script setup>
import { useToast } from 'vue-toastification'
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useFavicon } from '@vueuse/core'
import moment from 'moment'
import DashboardLayout from './layout/DashboardLayout'
import BlankLayout from './layout/BlankLayout'
import LegacyLayout from './layout/LegacyLayout'
import useAppData from './composables/useAppData'
import { useAuthStore } from './store/auth'
import { useAppStore } from '@/store/app'
import { useGlobalStore } from '@/store/global'
import Api from '@/utils/api'

const authStore = useAuthStore()
const appStore = useAppStore()
const globalStore = useGlobalStore()
const { currentRoute } = useRouter()
const toast = useToast()
const { appId } = useAppData()
const icon = useFavicon()

const layout = computed(() => {
  if (currentRoute.value.meta.layout === 'dashboard')
    return DashboardLayout
  if (currentRoute.value.meta.layout === 'blank')
    return BlankLayout

  return LegacyLayout
})

function initAppColor(colorSet) {
  let variables = ''

  colorSet.forEach((color) => {
    variables += `--color-${color.name}: ${color.value};`
  })

  document.documentElement.style.cssText = variables
}

async function getAppData() {
  try {
    globalStore.$patch({ isLoading: true })

    const { data } = await Api.get(`${process.env.VUE_APP_API_URL}/multi-tenant/tenant`, {
      headers: {
        Domain: appId.value,
      },
    })

    appStore.appData = data.data

    globalStore.$patch({ isLoading: false })

    if (data.data?.color) {
      initAppColor(data.data.color)
    }

    if (data.data?.logo_square) {
      icon.value = data.data.logo_square
    }

    if (data.data?.server_time) {
      localStorage.setItem('timeDifference', moment().diff(moment(new Date(Date.parse(data.data?.server_time))), 'seconds'))
    }
  }
  catch (err) {
    console.log(err)
    toast.error('Ooppss.. Ada Kesalahan')

    globalStore.$patch({ isLoading: false })
  }
}

async function getOtherApps() {
  try {
    const { data } = await Api.get(`${process.env.VUE_APP_API_URL}/multi-tenant/apps`)

    appStore.otherProducts = data
    globalStore.$patch({ isLoading: false })
  }
  catch (err) {
    toast.error('Ooppss.. Ada Kesalahan')
    globalStore.$patch({ isLoading: false })
  }
}

onMounted(() => {
  getAppData()
  getOtherApps()

  setTimeout(() => {
    if (currentRoute.value.query.q) {
      authStore.token = currentRoute.value.query.q
      window.location.replace('/')
    }
  }, 1000)
})
</script>

<template>
  <transition name="scroll-y-transition" mode="out-in">
    <component :is="layout" :key="layout">
      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition || 'scroll-x-transition'" mode="out-in">
          <component :is="Component" :key="route.path" />
        </transition>
      </router-view>
    </component>
  </transition>
</template>
